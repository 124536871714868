import { css } from "@emotion/react";
import { colors } from "../../assets/colors/colors";

export const styles = {
  container: css({
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    position: "absolute",
    top: "60px",
    right: "10px",
    borderRadius: "1rem",
    padding: "4px 4px opx 4px",
    zIndex: 2,
    backgroundColor: colors.turquoise,
  }),
  shareButton: css({
    padding: "4px 4px 0px 4px !important",
  }),
};
