import { css } from "@emotion/react";

export const styles = {
  container: css({
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    height: "100vh",
  }),
};
