import { ApiMap } from "../../Interfaces/Api";

export const apiMap: ApiMap = {
  occurences: {
    url: "person/occurences/",
    method: "post",
  },
  getEvents: {
    url: "batches/images/getAll/",
    method: "post",
  },
  getCollections: {
    url: "/collection/getAllExt/",
    method: "post",
  },
  getProfile: {
    url: "/person/metadata/",
    method: "post",
  },
  shortenUrl: {
    baseURL: "https://cutt.ly/api/api.php",
    url: "?key=:apiKey&short=:url",
    method: "get",
  },
  rebrandlyShortenUrl: {
    baseURL: "https://api.rebrandly.com",
    url: "/v1/links",
    method: "post",
    noAuth: true,
  },
  rebrandlyGetWorspaces: {
    baseURL: "https://api.rebrandly.com",
    url: "/v1/workspaces",
    method: "get",
    noAuth: true,
  },

  // auth: {
  //   url: "/Authenticate/",
  //   method: "post",
  //   mock: {
  //     stationId: "123123",
  //   },
  // },
  // getSessions: {
  //   url: "/:stationId/GetAllExistSessions/",
  //   method: "get",
  // },
};
