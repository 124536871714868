const awsconfig = {
  Auth: {
    identityPoolId: "eu-west-1:5bb2e9b8-9b53-4649-9afc-49827c23b6d5",
    region: "eu-west-1",
    userPoolId: "eu-west-1_GFMHfLAkE",
    oauth: {
      domain: "7amqavvfj9aohji0n8rf3hv2lr.auth.us-east-1.amazoncognito.com",
      scope: [
        "phone",
        "email",
        "openid",
        "profile",
        "aws.cognito.signin.user.admin",
      ],
      responseType: "token",
    },
    bucketName: "bucket-knesset-rk-dev-col",
    userPoolWebClientId: "2frr9lk76k9se5al45dc53mv7n",
    loginsKey: "cognito-idp.eu-west-1.amazonaws.com/eu-west-1_GFMHfLAkE",
    apiVersion: "2006-03-01",
    ACL: "public-read",
    signatureVersion: "v4",
    encryption: "aws:kms",
    KMSKeyId: "70dca56e-65db-4917-9e32-19886ef6007b",
  },
};

export default awsconfig;
