/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from "react";
import { styles } from "./Filters.styles";
import Select, { SingleValue } from "react-select";

import { translation } from "../../Services/translation";
import {
  eventsResponseToEventsList,
  // onDownload,
  downloadImage,
} from "../../Services/CommonFunctions";

import { api } from "../../Services/http/http.service";
import { EventsResponse, EventsSelect } from "../../Interfaces/MainInterfaces";

import share from "../../assets/icons/share.png";
import download from "../../assets/icons/download.png";
import cancel from "../../assets/icons/cancel.svg";
import { Button } from "@mui/material";
import ShareButtons from "../ShareButtons/ShareButtons";
import { colors } from "../../assets/colors/colors";

interface Props {
  resultNumber: number;
  getData: (isClear: boolean, params: { description: string | null }) => {};
  selectedPhotos: string[];
  resetSelectedPhotos: () => void;
}
const Filters: FC<Props> = ({
  resultNumber,
  getData,
  selectedPhotos,
  resetSelectedPhotos,
}) => {
  const [options, setOptions] = useState<EventsSelect[]>([]);
  const [filter, setFilter] =
    useState<SingleValue<EventsSelect | { key: string; value: string }>>();
  const [shareActive, setShareActive] = useState(false);

  const getEvents = async () => {
    console.log(
      "process.env.REACT_APP_COLLECTION_ID: ",
      process.env.REACT_APP_COLLECTION_ID
    );
    const res: EventsResponse = await api({
      type: "getEvents",
      data: { collectionKey: process.env.REACT_APP_COLLECTION_ID },
    });
    setOptions(eventsResponseToEventsList(res));
  };

  useEffect(() => {
    getCollections();
    getEvents();
  }, []);

  useEffect(() => {
    setShareActive(false);
  }, [selectedPhotos]);

  const getCollections = async () => {
    const res = await api({
      type: "getCollections",
      data: { collectionName: null, tags: null },
    });
  };

  const handleChange = (
    valAndLabel: SingleValue<EventsSelect | { key: string; value: string }>
  ) => {
    setFilter(valAndLabel);

    const selected = options.find(
      (option: any) => option.value === valAndLabel?.value
    );

    getData(true, { description: selected?.label || null });
  };

  const handleDownloadImg = () => {
    selectedPhotos?.forEach((url: any) => downloadImage(url));
  };

  const handleShare = () => {
    setShareActive((prev) => !prev);
  };

  const handleResetSelectedPhotos = () => {
    setShareActive(false);
    resetSelectedPhotos();
  };

  return (
    <div css={styles.container}>
      {selectedPhotos?.length ? (
        <>
          <div css={styles.resultNumber}>
            <div>{`${translation.he.clean} (${selectedPhotos.length})`}</div>
            <Button
              css={styles.photoOptions}
              onClick={handleResetSelectedPhotos}
            >
              <img css={styles.cancelImg} src={cancel} />
            </Button>
          </div>
          <div css={styles.actionsHolder}>
            <Button css={styles.photoOptions} onClick={handleShare}>
              <img css={styles.shareImg} src={share} />
            </Button>
            <Button css={styles.photoOptions} onClick={handleDownloadImg}>
              <img css={styles.downloadImg} src={download} />
            </Button>
            {shareActive && (
              <ShareButtons
                selectedPhotos={selectedPhotos}
                setShareActive={() => setShareActive((prev) => !prev)}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div css={styles.resultNumber}>
            <div>
              {translation.he.numOfResult}
              <strong>{` ${resultNumber}`}</strong>
            </div>
          </div>
          <Select
            css={styles.select}
            classNamePrefix="select"
            value={filter}
            isDisabled={false}
            isLoading={false}
            isClearable={true}
            isRtl={true}
            isSearchable={false}
            name="color"
            placeholder={translation.he.searchPlaceHolder}
            options={options}
            onChange={handleChange}
            theme={(theme) => ({
              ...theme,
              borderRadius: 0,
              colors: {
                ...theme.colors,
                neutral80: colors.darkGrey,
                color: colors.grey,
              },
            })}
          />
        </>
      )}
    </div>
  );
};

export default Filters;
