export const translation = {
  he: {
    numOfResult: 'סה"כ תמונות',
    filter: "סינון",
    nonFilter: "הכל",
    searchPlaceHolder: "בחר אירוע",
    noPhotosText: "לא נמצאו תמונות שלך, אנא נסה שנית מאוחר יותר",
    clean: "נקה הכל"
  },
};
