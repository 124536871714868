import { LazyLoad } from 'react-lazyload';
import { css } from "@emotion/react";

export const styles = {
  container: css({}),
  skeleton: css({
    width: "100%",
    height: "15px",
    display: "block",
    background:
      "linear-gradient(to right,rgba(255, 255, 255, 0),rgba(255, 255, 255, 0.5) 50%,rgba(255, 255, 255, 0) 80%),lightgray",
    backgroundRepeat: "repeat-y",
    backgroundSize: "50px 500px",
    backgroundPosition: "0 0",
    // animation: 'shine 1s infinite'
  }),
  spinner: css({
    margin: "0 auto",
    width: "50px",
    height: "40px",
    textAlign: "center",
    fontSize: "10px",
  }),
  spinnerItem: css({
    backgroundColor: "#999",
    height: "100%",
    width: "6px",
    display: "inline-block",
    animation: "sk-stretchdelay 1.2s infinite ease-in-out",
  }),
  photoHolder: css({
    display: "flex",
    flexDirection: "column",
    // padding: '14px',
    paddingBottom: "10px",
  }),
  decriptionPhoto: css({
    fontSize: "16px",
    fontWeight: "600",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    direction: 'rtl',
  }),
  photoDataContainer: css({
    fontFamily: "Rubik, sans-serif",
    fontWeight: "100",
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "6px",
  }),
  photoImg: css({
    borderRadius: "0.4rem",
  }),
  LazyLoad: css({
    height: "inherit",
    width: "inherit",
    '& img': {
      height:"inherit !important",
    }
  })
};
