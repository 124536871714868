import {
  ImagesResponse,
  GalleryPhotosData,
  EventsResponse,
  EventsSelect,
} from "../Interfaces/MainInterfaces";
import Axios from "axios";
import fileDownload from "js-file-download";

import AWS from "aws-sdk";
import { S3 } from "@aws-sdk/client-s3";

const NAME_PREFIX = `https://bucket-knesset-rk-dev-col.s3.amazonaws.com/Batch_Images/${process.env.REACT_APP_COLLECTION_ID}/`;

export let filterList: string[] = ["All"];

export const photosResponseToGalleryData = (
  data: ImagesResponse
): GalleryPhotosData => {
  let res = {
    ...data,
    photos: data.occurences.map((occurence) => {
      return {
        src: occurence.images.detected,
        width: 1,
        height: 1,
        alt: occurence.description,
      };
    }),
  };

  return res;
};

export const eventsResponseToEventsList = (
  data: EventsResponse
): EventsSelect[] => {
  let res = data.batches.map((event) => {
    return {
      value: event.batchKey,
      label: event.batchName,
    };
  });
  return res;
};

const getAuthorName = (url: string): string => {
  // let s3 = new AWS.S3();

  let urlArray = url.split("/");
  console.log({ urlArray });
  let bucket = urlArray[3];
  let key = `${urlArray[4]}/${urlArray[5]}`;
  console.log({ key, bucket });
  // let s3 = new AWS.S3({ params: { Bucket: bucket } });
  // console.log({ s3 });
  // let params = { Bucket: bucket, Key: key };
  // let params = { Bucket: bucket, Key: key, Expires: 60 };
  let authorName = `${Date.now().toString()}_unknown_author`;
  if (url.includes(NAME_PREFIX)) {
    authorName = url.split(NAME_PREFIX)[1].split(".")[0];
  }
  return authorName;
};
export const downloadImage = (url: string) => {
  const authorName = getAuthorName(url);
  Axios({
    url,
    method: "GET",
    responseType: "blob",
    // headers: {
    //   "Access-Control-Allow-Origin": "*",
    //   credentials: false,
    //   crossDomain: true,
    //   // "Content-Type": "application/json",
    // },
    // withCredentials: true,
    // credentials: "same-origin",
  })
    .then((res: any) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${authorName}.png`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })
    .then((res: any) => {
      console.log("fileDownload 2nd response: ", res);
    })
    .catch((err: any) => console.log("fileDownload error: ", { err }));
};

export const onDownload = async (
  url: string,
  shouldPreventDownload?: boolean
) => {
  console.log(shouldPreventDownload);
  let authorName = `${Date.now().toString()}_unknown_author`;
  if (url.includes(NAME_PREFIX)) {
    authorName = url.split(NAME_PREFIX)[1].split(".")[0];
  }

  let downloadedImg: any;
  const imageReceived = () => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    canvas.width = downloadedImg.width;
    canvas.height = downloadedImg.height;
    canvas.innerText = downloadedImg.alt;

    context?.drawImage(downloadedImg, 0, 0);
    const imgAsBase64 = canvas.toDataURL("image/png");
    downloadFile(authorName, imgAsBase64, "png");
  };
  const downloadFile = (fileName: string, data: any, fileFormat: string) => {
    const linkSource = "data:" + fileFormat + ";base64" + data;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.setAttribute("download", `${fileName}.png`);
    downloadLink.setAttribute("target", "_blank");
    !shouldPreventDownload && downloadLink.click();
  };

  downloadedImg = new Image();
  downloadedImg.crossOrigin = "Anonymous";
  downloadedImg.src = url;
  downloadedImg.addEventListener("load", imageReceived, false);
};

export const getPhotographerName = (imgString: string): string => {
  if (imgString.includes(NAME_PREFIX)) {
    let authorName = imgString.split(NAME_PREFIX)[1].split(".")[0];
    return authorName.replace(/[0-9]|[/_]|[/$%]/g, "");
  }
  return "";
};
