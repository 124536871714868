import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify, I18n } from "aws-amplify";
import awsConfig from "./Services/awsConfig";
import { AmplifyProvider } from "@aws-amplify/ui-react";
import { Translations } from "@aws-amplify/ui-components";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure(awsConfig);
I18n.setLanguage("he");

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AmplifyProvider>
    <App />
  </AmplifyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
