import axios from "axios";
import { generatePath } from "react-router-dom";
import { HttpRequestCfg } from "../../Interfaces/Api";
import { apiMap } from "./api.map";
import { Auth } from "aws-amplify";

export const api = async <T>(cfg: HttpRequestCfg): Promise<T> => {
  let axiosInstance = axios.create({
    baseURL: apiMap[cfg.type].baseURL || "https://bo.mkpic.org/api/",
    // apiMap[cfg.type].baseURL || "https://knesset-dev.rk.comm-it.com/api/",
  });
  let session = await Auth.currentSession();
  let accessToken = session.getAccessToken();
  let jwtToken = accessToken.getJwtToken();
  const headers = { ...cfg.headers, Authorization: jwtToken };
  if (apiMap[cfg.type].noAuth) delete headers.Authorization;
  return new Promise<T>((resolve, reject) => {
    if (apiMap[cfg.type].mock) {
      setTimeout(() => {
        resolve(apiMap[cfg.type].mock as T);
        return;
      }, 1000);
    } else {
      return axiosInstance({
        url: generatePath(apiMap[cfg.type].url, cfg.urlParams),
        data: cfg.data,
        params: cfg.query || {},
        method: apiMap[cfg.type].method,
        responseType: "json",
        headers,
      }).then(
        (response) => {
          resolve(response.data);
        },
        (error) => {
          reject(error);
        }
      );
    }
  });
};
