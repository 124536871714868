import { colors } from "../../assets/colors/colors";
import { css } from "@emotion/react";

export const styles = {
  container: css({
    width: "100%",
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    borderBottom: `1px solid ${colors.turquoise}`,
  }),
  ProfileHolder: css({
    height: "30px",
    display: "flex",
    justifyContent: "center",
  }),
  iconHolder: css({
    height: "30px",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 550px)": {
      position: 'absolute',
      right: '50%',
      left: '50%',
    },
  }),
  iconProfile: css({
    border: `1px solid ${colors.turquoise}`,
    borderRadius: "50%",
    padding: "1px",
    width: "30px",
    // backgroundPosition: 'center center',
    objectFit: "fill", //cover || contain
    // backgroundRepeat: 'no-repeat',
  }),
};
