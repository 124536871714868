import { css } from "@emotion/react";

export const styles = {
  menuButton: css({
    position: "relative",
    "& .MuiButton-root": {
      minWidth: "30px",
    },
  }),
  DropDown: css({
    position: "absolute",
    bottom: "0px",
  }),
};
