/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import PlaceHolder from "../../../assets/images/profile.png";
import expand from "../../../assets/icons/expand.svg";
import { styles } from "./SelectedImage.styles";
import { getPhotographerName } from "../../../Services/CommonFunctions";

interface Props {
  photo: any;
  margin: string;
  direction?: string;
  top: string;
  left: string;
  selected: boolean;
  index: number;
  updateSelectedPhotos: (url: string, isSelected: boolean) => void;
  onExpandClick: (e: any, index: number) => void;
}

const Checkmark = ({
  selected,
  isHover,
}: {
  selected: boolean;
  isHover: boolean;
}) => (
  <div
    style={
      selected || isHover
        ? { left: "4px", top: "4px", position: "absolute", zIndex: "1" }
        : { display: "none" }
    }
  >
    <svg
      style={{ fill: "white", position: "absolute" }}
      width="24px"
      height="24px"
    >
      <circle cx="12.5" cy="12.2" r="8.292" />
    </svg>
    <svg
      style={{ fill: isHover ? "#b1b2b3" : "#06befa", position: "absolute" }}
      width="24px"
      height="24px"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z" />
    </svg>
  </div>
);
const imgStyle = {
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
const selectedImgStyle = {
  transform: "translateZ(0px) scale3d(0.9, 0.9, 1)",
  transition: "transform .135s cubic-bezier(0.0,0.0,0.2,1),opacity linear .15s",
};
const cont: any = {
  backgroundColor: "#eee",
  cursor: "pointer",
  overflow: "hidden",
  position: "relative",
};

const SelectedImage: FC<Props> = ({
  photo,
  margin,
  direction,
  top,
  left,
  selected,
  index,
  updateSelectedPhotos,
  onExpandClick,
}) => {
  const [isHover, setIsHover] = useState(false);
  const [photographerName, setPhotographerName] = useState("");
  const [photoSrc, setPhoroSrc] = useState(photo.src);

  useEffect(() => {
    setPhotographerName(getPhotographerName(photo.src));
  }, []);

  if (direction === "column") {
    cont.position = "absolute";
    cont.left = left;
    cont.top = top;
  }

  const handleOnClick = () => {
    updateSelectedPhotos(photo.src, !selected);
  };

  const handleExpandClick = (e: any) => {
    onExpandClick(e, index);
  };

  return (
    <div css={styles.photoHolder}>
      <div
        style={{
          marginBottom: "10px",
          margin,
          height: photo.height,
          width: photo.width,
          ...cont,
        }}
        css={!selected ? "not-selected" : ""}
      >
        <Checkmark selected={selected ? true : false} isHover={isHover} />
        <div
          style={{
            right: "4px",
            top: "4px",
            position: "absolute",
            zIndex: "1",
            borderRadius: "0.4rem",
          }}
          onClick={handleExpandClick}
        >
          <img src={expand} alt="expand image" width="20px" />
        </div>
        {/* TODO: change the placeholder to a real one */}
        {/* <div css={styles.skeleton} /> */}
        {/* <LazyLoad css={styles.LazyLoad} placeholder={<img src={PlaceHolder} />}> */}
        <img
          css={styles.photoImg}
          alt={photo.title}
          style={
            selected ? { ...imgStyle } : { ...imgStyle }
            //   isSelected ? { ...imgStyle, ...selectedImgStyle } : { ...imgStyle }
          }
          // {...photo}
          src={photoSrc}
          height={photo.height}
          width={photo.width}
          onClick={handleOnClick}
          loading="lazy"
        />
        {/* </LazyLoad> */}
        <style>{`.not-selected:hover{outline:2px solid #06befa}`}</style>
      </div>
      <div style={{ width: photo.width - 10 }} css={styles.photoDataContainer}>
        {photographerName ? (
          <div>
            <span title={photographerName}>@{photographerName}</span>
            &nbsp;&nbsp;&nbsp;<span style={{ color: "#808080" }}>|</span>
            &nbsp;&nbsp;&nbsp;
          </div>
        ) : null}
        <div title={photo.alt} css={styles.decriptionPhoto}>
          {photo.alt}
        </div>
      </div>
    </div>
  );
};

export default SelectedImage;
