import "../../App.css";
import { colors } from "../../assets/colors/colors";
import { css } from "@emotion/react";

export const styles = {
  container: css({
    width: "100%",
    minHeight: "70px",
    height: "max-content",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px",
  }),
  select: css({
    width: '200px',
    minWidth: "50px",
    fontFamily: "Rubik, sans-serif",
    '& ::-webkit-scrollbar': {
        width: '16px',
        height: '16px',
    },
    '& ::-webkit-scrollbar-thumb': {
      background: '#cbd5e0',
      borderRadius: '100vh',
      border: '3px solid #edf2f7',
    },
    '& ::-webkit-scrollbar-track': {
      borderRadius: '100vh',
      background: '#edf2f7',
    },
    '& .select__control': {
      backgroundColor: colors.lightGreyV2,
      border: 'none',
      borderRadius: '0.3rem',
      '& .select__indicators': {
        '& .select__indicator-separator': {
          // backgroundColor: 'transparent',
        }
      }
    },
    '& .select__menu': {
      fontFamily: "Rubik, sans-serif",
      borderRadius: '0.3rem',
      marginTop: '1px',
      '& .select__menu-list': {
        '& .select__option':{
          color: colors.darkGrey,
        },
        '& .select__option--is-focused': {
          backgroundColor: colors.lightTurquoise,
        },
        '& .select__option--is-selected': {
          color: colors.lightGreyV2,
          backgroundColor: colors.turquoise,
        },
      }
    }
  }),
  resultNumber: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: "16px",
    fontFamily: "Rubik, sans-serif",
    width: "max-content",
    "@media (max-width: 550px)": {
      minWidth: 'max-content',
      paddingRight: '5px',
    },
  }),
  photoOptions: css({
    minWidth: "38px",
    height: "38px",
    padding: "10px 0px",
    borderRadius: "1rem",
  }),
  actionsHolder: css({
    position: "relative",
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end', 
    alignItems: 'center',
  }),
  cancelImg: css({
    width: "15px",
  }),
  shareImg: css({
    width: "15px",
    "@media (max-width: 550px)": {
      width: "20px",
    },
  }),
  downloadImg: css({
    width: "17px",
    "@media (max-width: 550px)": {
      width: "23px",
    },
  }),
  selectedNumber: css({
    fontSize: "16px",
    fontweight: "bold",
    fontFamily: "Rubik, sans-serif",
    paddingRight: "2px",
    "@media (max-width: 550px)": {
      fontSize: "18px",
    },
  }),
}
