/** @jsxImportSource @emotion/react */
import React, {
  FC,
  useState,
  useCallback,
  ComponentType,
  PropsWithChildren,
} from "react";
import Gallery from "react-photo-gallery";
import SelectedImage from "./SelectedImage/SelectedImage";
import InfiniteScroll from "react-infinite-scroll-component";

import { styles } from "./PhotoGallery.styles";
import NoPhotos from "../../assets/images/noPhotos.png";

import { translation } from "../../Services/translation";
import { GalleryPhotosData } from "../../Interfaces/MainInterfaces";
import { CircularProgress } from "@mui/material";
import Carousel, { Modal, ModalGateway } from "react-images";
import { getPhotographerName } from "../../Services/CommonFunctions";

const ModalGatewayWithChildren =
  ModalGateway as ComponentType<PropsWithChildren>;

interface Props {
  photosData: GalleryPhotosData | null;
  getData: any;
  pageNumber: any;
  updateSelectedPhotos: (url: string, isSelected: boolean) => void;
  selectedPhotos: string[];
  isLoadingData: boolean;
}
const PhotoGallery: FC<Props> = ({
  photosData,
  getData,
  pageNumber,
  updateSelectedPhotos,
  selectedPhotos,
  isLoadingData,
}) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  console.log({ isLoadingData });
  const imageRenderer = ({ left, top, photo, index, key }: any) => {
    const isSelected = selectedPhotos.includes(photo.src);

    return (
      <SelectedImage
        selected={isSelected}
        index={index}
        margin={"2px"}
        photo={photo}
        left={left}
        key={key}
        top={top}
        updateSelectedPhotos={updateSelectedPhotos}
        onExpandClick={openLightbox}
      />
    );
  };

  const handleNext = () => {
    console.log("handle next", { photosData });
    if (!photosData) return;
    pageNumber.current = photosData.pageNumber + 1;
    getData();
  };

  const openLightbox = useCallback((_event: any, index: number) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  // if (isLoadingData) {
  //   return (
  //     <div
  //       style={{
  //         height: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //       }}
  //     >
  //       <CircularProgress size="140px" />
  //     </div>
  //   );
  // }

  return (
    <div
      id="scrollContainer"
      style={{ height: "calc(100% - 120px)", overflowY: "auto" }}
    >
      {photosData?.photos.length ? (
        <InfiniteScroll
          scrollableTarget="scrollContainer"
          dataLength={photosData.photos.length}
          next={handleNext}
          hasMore={photosData.hasNextPage}
          loader={
            <div style={{ display: "flex", justifyContent: "center" }}>
              {/* <CircularProgress /> */}
              <h4>Loading...</h4>
            </div>
          }
        >
          <Gallery photos={photosData.photos} renderImage={imageRenderer} />
          <ModalGatewayWithChildren>
            {viewerIsOpen ? (
              <Modal onClose={closeLightbox}>
                <Carousel
                  currentIndex={currentImage}
                  views={photosData.photos.map((x: any) => ({
                    ...x,
                    srcset: x.src,
                    caption: `@${getPhotographerName(x.src)} | ${x.alt}`,
                  }))}
                />
              </Modal>
            ) : null}
          </ModalGatewayWithChildren>
        </InfiniteScroll>
      ) : (
        <div css={styles.noPhotosPageContainer}>
          <div css={styles.noPhotosContainer}>
            <img src={NoPhotos} style={{ maxWidth: "90vw" }} />
            <div style={{ marginTop: "20px", textAlign: "center" }}>
              {translation.he.noPhotosText}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
