/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState, useRef } from "react";
import MainToolbar from "../../Components/MainToolbar/MainToolbar";
import Filters from "../../Components/Filters/Filters";
import PhotoGallery from "../../Components/PhotoGallery/PhotoGallery";

import {
  ImagesResponse,
  GalleryPhotosData,
} from "../../Interfaces/MainInterfaces";
import { api } from "../../Services/http/http.service";
import {
  photosResponseToGalleryData,
  // onDownload,
} from "../../Services/CommonFunctions";

import { styles } from "./Main.styles";

interface Props {
  user: any;
  signOut: any;
}

const Main: FC<Props> = ({ user, signOut }) => {
  const [photosData, setPhotosData] = useState<GalleryPhotosData>({
    hasNextPage: false,
    itemsInPage: 0,
    photos: [],
    occurencesCount: 0,
    pageNumber: 0,
    totalItems: 0,
  });
  const [selectedPhotos, setSelectedPhotos] = useState<string[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const pageNumber = useRef(1);

  useEffect(() => {
    const LOADING_NUMBER = Number(
      window.sessionStorage.getItem("LOADING_NUMBER") || 0
    );
    !LOADING_NUMBER && window.location.reload();
    window.sessionStorage.setItem(
      "LOADING_NUMBER",
      `${LOADING_NUMBER || 0 + 1}`
    );
    getData();
  }, []);

  const activeUser = user.username;

  const getData = async (
    isClearPhotos?: boolean,
    params?: { description: string | null }
  ) => {
    setIsLoadingData(true);
    console.log("description", { params, activeUser });

    const res: ImagesResponse = await api({
      type: "occurences",
      data: {
        civilId: activeUser,
        collectionKey: ["dudik"].includes(activeUser)
          ? "202"
          : process.env.REACT_APP_COLLECTION_ID,
        confidence: null,
        description: null,
        itemsInPage: 50,
        pageNumber: pageNumber.current,
        ...params,
      },
    });
    setIsLoadingData(false);
    const newPhotosData: GalleryPhotosData = photosResponseToGalleryData(res);

    // newPhotosData.photos.forEach((photo: any) => {
    //   onDownload(photo.src, true);
    // });
    setPhotosData((prev) => {
      return {
        ...prev,
        occurencesCount: newPhotosData.occurencesCount,
        hasNextPage: newPhotosData.hasNextPage,
        pageNumber: newPhotosData.pageNumber,
        photos: isClearPhotos
          ? newPhotosData.photos
          : [...prev?.photos, ...newPhotosData.photos],
      };
    });
  };

  const handleSelectedPhotos = async (url: string, isSelected: boolean) => {
    setSelectedPhotos((prev) => {
      if (isSelected) {
        return [...prev, url];
      } else {
        const index = prev.indexOf(url);
        if (index > -1) {
          const prevUpdated = prev?.filter((prevUrl) => prevUrl !== url);
          return [...prevUpdated];
        }
      }
      return [...prev];
    });
  };

  return (
    <div css={styles.container}>
      <div>
        <MainToolbar activeUser={activeUser} signOut={signOut} />
        <Filters
          resultNumber={photosData.occurencesCount}
          getData={getData}
          selectedPhotos={selectedPhotos}
          resetSelectedPhotos={() => {
            setSelectedPhotos([]);
          }}
        />
      </div>
      <PhotoGallery
        photosData={photosData}
        getData={getData}
        pageNumber={pageNumber}
        updateSelectedPhotos={handleSelectedPhotos}
        selectedPhotos={selectedPhotos}
        isLoadingData={isLoadingData}
      />
    </div>
  );
};
export default React.memo(Main);
