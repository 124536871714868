import React from "react";
import kenessetImg from "./assets/images/knessetSvg.svg";
import Main from "./Pages/Main/Main";
import "./App.css";
import {
  Authenticator,
  useAuthenticator,
  useTheme,
  View,
  Image,
  Text,
  Heading,
  Button,
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";

const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <View
        textAlign="center"
        padding={tokens.space.large}
        style={{ margin: "10vh 0 10px 0" }}
      >
        <Image className="knesset-img" src={kenessetImg} alt="knesset icon" />
      </View>
    );
  },

  SignIn: {
    Header() {
      const { tokens } = useTheme();

      return (
        <Heading
          textAlign="center"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          כניסה למערכת
        </Heading>
      );
    },

    Footer() {
      // const { toResetPassword } = useAuthenticator();

      // return (
      //   <View textAlign="center">
      //     <Button
      //       fontWeight="normal"
      //       onClick={toResetPassword}
      //       size="small"
      //       variation="link"
      //     >
      //       Reset Password
      //     </Button>
      //   </View>
      // );
      return <View />;
    },
  },
  ConfirmSignIn: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          textAlign="center"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          כניסה למערכת
        </Heading>
      );
    },
  },
  ResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          Enter Information:
        </Heading>
      );
    },
    Footer() {
      return <Text>Footer Information</Text>;
    },
  },
  ConfirmResetPassword: {
    Header() {
      const { tokens } = useTheme();
      return (
        <Heading
          textAlign="center"
          padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
          level={3}
        >
          כניסה למערכת
        </Heading>
      );
    },
    Footer() {
      return <Text />;
    },
  },
};

const formFields = {
  signIn: {
    username: {
      labelHidden: true,
      placeholder: "שם משתמש",
    },
    password: {
      placeholder: "סיסמה",
    },
  },
  confirmSignIn: {
    confirmation_code: {
      labelHidden: true,
      placeholder: "SMS הכנס קוד",
      isRequired: true,
    },
  },
  // signUp: {
  //   password: {
  //     labelHidden: false,
  //     label: "Password:",
  //     placeholder: "Enter your Password:",
  //     isRequired: false,
  //     order: 2,
  //   },
  //   confirm_password: {
  //     labelHidden: false,
  //     label: "Confirm Password:",
  //     order: 1,
  //   },
  // },
  forceNewPassword: {
    password: {
      labelHidden: true,
      placeholder: "סיסמה חדשה",
    },
    confirm_password: {
      labelHidden: true,
      placeholder: "אשר סיסמה",
    },
  },
  resetPassword: {
    username: {
      labelHidden: false,
      placeholder: "Enter your email:",
    },
  },
  confirmResetPassword: {
    confirmation_code: {
      labelHidden: false,
      placeholder: "Enter your Confirmation Code:",
      label: "New Label",
      isRequired: false,
    },
    confirm_password: {
      labelHidden: false,
      placeholder: "Enter your Password Please:",
    },
  },
  setupTOTP: {
    QR: {
      totpIssuer: "test issuer",
      totpUsername: "amplify_qr_test_user",
    },
    confirmation_code: {
      labelHidden: true,
      placeholder: "הכנס קוד SMS",
      isRequired: true,
    },
  },
};

function App() {
  const dict = {
    he: {
      [Translations.SIGN_IN_TEXT]: "התחבר",
      [Translations.BACK_TO_SIGN_IN]: "חזרה לעמוד הקודם",
      [Translations.CONFIRM]: "אישור",
      [Translations.INVALID_USERNAME]: "המשתמש אינו קיים",
      [Translations.CHANGE_PASSWORD]: "החלפת סיסמה",
    },
  };

  I18n.putVocabularies(dict);
  return (
    <Authenticator formFields={formFields} components={components} hideSignUp>
      {({ signOut, user }: any) => <Main signOut={signOut} user={user} />}
    </Authenticator>
  );
}

export default App;
