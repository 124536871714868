import { css } from "@emotion/react";

export const styles = {
  noPhotosPageContainer: css({
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }),
  noPhotosContainer: css({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    transform: "translate(0, -8vh)",
    padding: "0 10px",
  }),
};
