/** @jsxImportSource @emotion/react */
import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import dropDown from "../../assets/images/Dropdown.png";
import { styles } from "./DropDownMenu.styles";

export default function DropDownMenu(props: any) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogOut = () => {
    setAnchorEl(null);
    props.handleLogout();
  };

  return (
    <div css={styles.menuButton}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <img src={dropDown} alt="drop-down" />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        css={styles.DropDown}
      >
        <MenuItem onClick={handleLogOut}>התנתקות</MenuItem>
      </Menu>
    </div>
  );
}
