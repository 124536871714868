/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState, useMemo } from "react";
import {
  EmailIcon,
  EmailShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import CircularProgress from "@mui/material/CircularProgress";
import { styles } from "./ShareButtons.styles";
import { api } from "../../Services/http/http.service";

interface Props {
  selectedPhotos: string[];
  setShareActive: () => void;
}
const ShareButtons: FC<Props> = ({ selectedPhotos, setShareActive }) => {
  // const encodedSelectedPhotos = useMemo(
  //   () => encodeURIComponent(selectedPhotos.join("\r\n\r\n\r\nlink: ")),
  //   [selectedPhotos]
  // );
  const [shareAvailable, setShareAvailable] = useState(false);
  const [shortUrlsString, setShortUrlsString] = useState<string>("");
  const [shortEncodeURIUrlsString, setEncodeURIUrlsString] =
    useState<string>("");

  useEffect(() => {
    getShortenerUrl(selectedPhotos);
  }, []);

  const getShortenerUrl = async (urls: string[]) => {
    const workspaces: any = await api({
      type: "rebrandlyGetWorspaces",
      headers: {
        accept: "application/json",
        apikey: process.env.REACT_APP_REBRANDLY_API_KEY,
      },
    });
    let allShortenerUrlsRes = await Promise.all(
      urls.map((url) => {
        return api({
          type: "rebrandlyShortenUrl",
          headers: {
            apikey: process.env.REACT_APP_REBRANDLY_API_KEY,
            workspace: workspaces[0].id,
          },
          data: {
            destination: url,
          },
        });
      })
    );

    const _shortUrls = allShortenerUrlsRes.map(
      (urlObj: any) => urlObj.shortUrl
    );
    setShortUrlsString(_shortUrls.join("\r\n"));
    setEncodeURIUrlsString(encodeURIComponent(_shortUrls.join("\r\n")));
    setShareAvailable(true);
  };

  return (
    <div css={styles.container}>
      {shareAvailable ? (
        <WhatsappShareButton
          css={styles.shareButton}
          onClick={setShareActive}
          url={shortUrlsString}
        >
          <WhatsappIcon size={40} round={true} />
        </WhatsappShareButton>
      ) : (
        <div style={{ padding: "3px" }}>
          <CircularProgress size="40px" />
        </div>
      )}
      {shareAvailable ? (
        /* <EmailShareButton
        css={styles.shareButton}
        onClick={setShareActive}
        url={selectedPhotos.join("\r\n\r\n\r\nlink: ")}
      > */
        <EmailIcon
          size={40}
          round={true}
          onClick={() =>
            (window.location.href = `mailto:email@example.com?body=${shortEncodeURIUrlsString}`)
          }
        />
      ) : (
        /* </EmailShareButton> */
        <div style={{ padding: "3px" }}>
          <CircularProgress size="40px" />
        </div>
      )}
      {shareAvailable ? (
        <TwitterShareButton
          css={styles.shareButton}
          onClick={setShareActive}
          url={shortUrlsString}
        >
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
      ) : (
        <div style={{ padding: "3px" }}>
          <CircularProgress size="40px" />
        </div>
      )}
    </div>
  );
};

export default ShareButtons;
