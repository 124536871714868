/** @jsxImportSource @emotion/react */
import React, { FC, useEffect, useState } from "react";
import kenessetImg from "../../assets/icons/knesset.png";
import DropDownMenu from "../Menu/DropDownMenu";
import { api } from "../../Services/http/http.service";
import { ProfileImageResponse } from "../../Interfaces/MainInterfaces";
import { styles } from "./MainToolbar.styles";
import profileImg from '../../assets/images/profile.png'
interface Props {
  signOut: any;
  activeUser: string;
}

const MainToolbar: FC<Props> = ({ signOut, activeUser }: any) => {
  const [profileIcon, setProfileIcon] = useState("");

  const getProfileData = async () => {
    const res: ProfileImageResponse = await api({
      type: "getProfile",
      data: {
        civilId: activeUser,
      },
    });
    setProfileIcon(res.profileImages?.[0]?.imageUrl || "");
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const handleLogout = () => {
    signOut();
  };

  return (
    <div css={styles.container}>
      <div css={styles.ProfileHolder}>
        <DropDownMenu handleLogout={handleLogout} />
        <img
          css={styles.iconProfile}
          src={profileIcon || profileImg}
          alt="profile image"
        />
      </div>
      <div css={styles.iconHolder}>
        <img src={kenessetImg} alt="knesset icon" />
      </div>
    </div>
  );
};

export default MainToolbar;
